import React from "react";
import { Link } from "react-router-dom";
import InnerBanner from "../common/InnerBanner/InnerBanner";
import AboutUs from "../home/aboutUs/AboutUs";
import Testimonials from "../home/testimonials/Testimonials";

function AboutUsC({ content }) {
  return (
    <>
      {/* <InnerBanner/> */}
      <section
        className="page-title page-title-2"
        style={{
          backgroundImage:
            'url("http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/10/image-26.png")',
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="title">
                <h1>{content.aboutus}</h1>
              </div>
              <ul className="bread-crumb clearfix">
                <li className="breadcrumb-item">
                  <Link to="/home">{content.home}&nbsp;</Link>
                </li>
                <li className="breadcrumb-item">{content.aboutus}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <AboutUs content={content} />
      <Testimonials content={content} />
    </>
  );
}
export default AboutUsC;
