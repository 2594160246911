import React, { Component } from "react";
import Slider from "react-slick";
import { RxCross2 } from "react-icons/rx";

import twoHuML from "../../../nafl-html/images/img-slider/200mlBottle.png";
import productsize2 from "../../../nafl-html/images/img-slider/productTwo2.jpeg";

import carton200 from "../../../nafl-html/images/img-slider/200ml.png";
import container from "../../../nafl-html/images/img-slider/container.png";
import carton330 from "../../../nafl-html/images/img-slider/330ml.png";
import cartondetails from "../../../nafl-html/images/img-slider/cartonDetails.png";

import bottleMl1 from "../../../nafl-html/images/bottle-ml/nafl1-min.jpg";
import bottleMl2 from "../../../nafl-html/images/bottle-ml/nafl2-min.jpg";

function OurProducts({ content }) {
  const language = window.localStorage.getItem("language");
  var settings = {
    dots: true,
    infinite: false,
    speed: 3000,
    autoplaySpeed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="product-section pt_120-pt">
        <div className="auto-container">
          <div className="sec-title mb_10 text-center">
            <h5 className="mb_20 fs_16 fw_medium text-uppercase theme_color ff_rubik">
              {content.ourProducts?.header}
            </h5>{" "}
            <h2 className="fs_45 fw_sbold">{content.ourProducts?.title}</h2>{" "}
          </div>

          <section className="main_banner_sec-1">
            <div className="auto-container">
              <div className="banner_img_sec">
                <img
                  src={language == "english" ? bottleMl1 : bottleMl2}
                  alt=""
                  className="main_banner_img_1"
                />
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="ownSlider">
        <div className="auto-container">
          <div className="our_slider ">
            <Slider {...settings}>
              <div className="slid_box">
                <img src={productsize2} />
              </div>

              <div className="slid_box">
                <img src={container} className="img-fluid" />
              </div>

              <div className="slid_box">
                <img src={twoHuML} className="img-fluid" />
              </div>

              <div className="slid_box">
                <img src={cartondetails} className="img-fluid" />
              </div>

              <div className="slid_box">
                <img src={carton200} className="img-fluid" />
              </div>

              <div className="slid_box">
                <div className="per-carton-container">
                  <RxCross2 className="iconss-cross" />
                  <h4 className="bottle_pr_carton">40</h4>
                </div>
                <img src={carton330} />
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
export default OurProducts;
