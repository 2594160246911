import React from "react";
function AboutUs({ content }) {
  const language = window.localStorage.getItem("language");
  return (
    <>
      <section className="why-chooseus-three pt_120-pt">
        <div className="aboutus_container">
          <div className="row">
            <div className="col-lg-6 image-column z_9 mb_30">
              <div className="why-choose-image clearfix">
                <div className="shape" />
                <div
                  className="image-one wow animated fadeInUp animated animated"
                  data-wow-delay="0.1s"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <img
                    decoding="async"
                    src="http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/09/image-27.png"
                    alt="Awesome Image"
                  />
                </div>
                <div
                  className="image-two wow animated fadeInDown animated animated"
                  data-wow-delay="0.1s"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "0.1s",
                    animationName: "fadeInDown",
                  }}
                >
                  <img
                    decoding="async"
                    src="http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/09/image-20.png"
                    alt="Awesome Image"
                  />
                </div>
                <div className="icon w_130 h_130 lh_140 text-center mr_15 theme_bg color_white fs_40 b_radius_50 mr_20">
                  <i className="icon-setting" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 content-column mb_30">
              <div className="outer-box why-chooseus-block">
                <div
                  className="inner-container wow animated fadeInUp animated animated"
                  data-wow-delay="0.1s"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <h5
                    className={`mb_20 fs_16 fw_medium text-uppercase ff_rubik theme_color ${
                      language == "arabic" && "text-end"
                    }`}
                  >
                    {content.aboutUsMain?.heading}
                  </h5>
                  <h2
                    className={`fs_45 fw_sbold mb_30 ${
                      language == "arabic" && "text-end"
                    }`}
                  >
                    {content.aboutUsMain?.title}
                  </h2>
                </div>
              </div>

              <div>
                <ul className={language == "arabic" && "text-end"}>
                  {content.aboutUsMain?.aboutDes.map((item) => {
                    return (
                      <li className="about_us_details mt-1 mb-3">
                        {item.details}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AboutUs;
