import { Link } from "react-router-dom";
import OurMission from "../../components/ourMission/OurMission";

function OurMissionPage({ content }) {
  console.log(content.ourMission?.heading);
  return (
    <>
      <section
        className="page-title page-title-2"
        style={{
          backgroundImage:
            'url("http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/10/image-26.png")',
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="title">
                <h1>{content.ourMission?.heading}</h1>
              </div>
              <ul className="bread-crumb clearfix">
                <li className="breadcrumb-item">
                  <Link to="/home">Home &nbsp;</Link>
                </li>
                <li className="breadcrumb-item">
                  {content.ourMission?.heading}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <OurMission content={content} />
    </>
  );
}
export default OurMissionPage;
