import React from "react";
import VideoComp from "../../components/video/Video";

function VideoPage({ content }) {
  return (
    <>
      <VideoComp content={content} />
    </>
  );
}
export default VideoPage;
