function WaterBenefits({ content }) {
  const language = window.localStorage.getItem("language");
  return (
    <div className="auto-container benefit_wrap ">
      <div className="row">
        <div className="float-water-img-box">
          <div className="float-water-content-box">
            <h1 className="benefit_heading_title helth_water_text mt-2 mb-2">
              {/* Fourteen benefits of drinking water */}
              {content.fourteenBenefitDrinkingWater?.benefitHeading}
            </h1>
            <ul
              className={`list_item_helth ${
                language == "arabic" && "text-center"
              }`}
            >
              {content.fourteenBenefitDrinkingWater?.des?.map((item) => {
                console.log(item);
                return (
                  <li className="benefit_list">
                    <p className="">
                      <small>{item.mainDes}</small>
                    </p>
                  </li>
                );
              })}
            </ul>

            <ul
              className={`benefit-item col-lg-10 left_item_benefit mt-3 ${
                language == "arabic" && "text-end"
              }`}
            >
              {content.fourteenBenefitDrinkingWater?.waterforhelthcomp?.map(
                (item, i) => {
                  return (
                    <li className="benefit_item_list">
                      <h5 className={language == "arabic" && "text-end"}>
                        {i + 1}. {item.fourteenBenefitTitle}
                      </h5>
                      <p className="list_details mt-1">
                        <small>{item.fourteenBenefitDes}</small>
                      </p>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WaterBenefits;
