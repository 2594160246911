import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../../../src/nafl-html/images/new-img/logo/logo.jpeg";
function Footer({ content }) {
  const language = window.localStorage.getItem("language");

  return (
    <>
      <footer className="main-footer">
        <div className="upper-box">
          <div className="auto-container">
            <div className="row align-items-center w-100">
              <div className="col-lg-3">
                <div className="logo mb_45 logo-footer">
                  <a href="#">
                    <img src={footerLogo} alt="Awesome Image" />
                  </a>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="top-info">
                  <div className="row">
                    <div className="col-md-6 column">
                      <div className="info-block">
                        <div className="icon">
                          <i className="icon-email" />
                        </div>
                        <div className="text">
                          <p>
                            {content.contactUsMain?.mainEmail} :{" "}
                            <a href="mailto: info@nafl.pro"> info@nafl.pro</a>
                          </p>{" "}
                          <p>
                            {content.contactUsMain?.Inquiries} :{" "}
                            <a href="mailto: info@nafl.pro">info@nafl.pro</a>
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 column">
                      <div className="info-block">
                        <div className="icon">
                          <i className="icon-phone" />
                        </div>
                        <div className="text">
                          <p>
                            {content.contactUsMain?.officialTelephone} :{" "}
                            <a href="tel:0029129102320">+966 5420 58487</a>
                          </p>{" "}
                          <p>
                            {content.contactUsMain?.mobile} :{" "}
                            <a href="tel:000232439493">+966 5420 58487</a>
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="widgets-section">
              <div className="row clearfix w-100">
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    id="paanee_about_company-2"
                    className="footer-widget widget_paanee_about_company"
                  >
                    <div className="about-widget">
                      <h3 className="widget-title">
                        {content.footer?.footerHeaderAaboutUs}
                      </h3>
                      <div
                        className={`text ${language == "arabic" && "text-end"}`}
                      >
                        {content.footer?.aboutDescrib}
                        <br></br>
                        <Link to="/about" className="btn_more">
                          {content.footer?.btn}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div id="pages-2" className="footer-widget widget_pages">
                    <h3 className="widget-title">
                      {content.footer?.footerHeaderUseful}
                    </h3>
                    <ul
                      className={
                        language == "arabic" && "text-end" && "arb_center"
                      }
                    >
                      <li className="page_item page-item-26">
                        <Link to="/home">{content.footer?.shiftPageHome}</Link>
                      </li>
                      <li className="page_item page-item-823">
                        <Link to="/about">
                          {content.footer?.shiftPageAboutUs}
                        </Link>
                      </li>
                      <li className="page_item page-item-43">
                        <Link to="/water-for-health">
                          {content.footer?.shiftPageWaterForHealth}
                        </Link>
                      </li>
                      <li className="page_item page-item-43">
                        <Link to="/gallery">
                          {content.footer?.shiftPageGallery}
                        </Link>
                      </li>
                      <li className="page_item page-item-43">
                        <Link to="/video">
                          {content.footer?.shiftPageVideo}
                        </Link>
                      </li>
                      <li className="page_item page-item-53">
                        <Link to="/contact">
                          {" "}
                          {content.footer?.shiftPageContactUs}
                        </Link>
                      </li>
                      <li className="page_item page-item-53">
                        <Link to="/mission">
                          {content.footer?.shiftPageOurMission}{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    id="nav_menu-3"
                    className="footer-widget widget_nav_menu"
                  >
                    <h3 className="widget-title">
                      {content.footer?.footerHeaderContact}
                    </h3>
                    <div className="menu-service-sidebar-menu-container">
                      <ul className="list color_white">
                        <li className="mb_45 flexbox_1">
                          <div>
                            <h4 className="fs_16 fw_bold color_white mb_10">
                              {content.footer?.ourAddress}
                            </h4>
                            <div className="text color_white">
                              {content.footer?.mainAddres}
                            </div>
                          </div>
                        </li>

                        <li className="mb_45 flexbox_1">
                          <div>
                            <h4 className="fs_16 fw_bold color_white mb_10">
                              {content.contactUsMain?.ourEmail}
                            </h4>
                            <div className="text color_white">
                              {content.contactUsMain?.mainEmail} :
                              <a
                                className="color_white color_white-2"
                                href="mailto:  info@nafl.pro"
                              >
                                {" "}
                                info@nafl.pro
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    id="paanee_subscribe_form-2"
                    className="footer-widget widget_paanee_subscribe_form"
                  >
                    {/* Newsletter Widget */}
                    <div className="newsletter-widget">
                      <h3 className="widget-title">
                        {content.footer?.footerHeaderAddress}
                      </h3>{" "}
                      <div className="newsletter-form">
                        <div className="ajax-sub-form">
                          <div>
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3628.7522360102853!2d46.74856795055732!3d24.563222663077898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0935a48f2163%3A0x12b6a518820d3b7e!2z2YXYpNiz2LPYqSDYp9mE2YXZiNmF2YrYp9ihINmE2YTYqtis2KfYsdip!5e0!3m2!1sen!2sin!4v1676291835853!5m2!1sen!2sin"
                              width={200}
                              height={200}
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="wrapper-box">
              <div className="copyright-text">
                {content.footer?.copyRights}
                <a
                  target="_blank"
                  href="https://www.abarissoftech.com/"
                  className="devlopedCompony"
                >
                  Abaris Softech
                </a>
              </div>
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.facebook.com/"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "rgba(255, 255, 255, 0.56)",
                    }}
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "rgba(255, 255, 255, 0.56)",
                    }}
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "rgba(255, 255, 255, 0.56)",
                    }}
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.skype.com/"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "rgba(255, 255, 255, 0.56)",
                    }}
                  >
                    <i className="fab fa-skype" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.twitter.com/"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "rgba(255, 255, 255, 0.56)",
                    }}
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
