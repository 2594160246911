import React from "react";
import "./nafl-html/css/bootstrap.css";
import "./nafl-html/css/frontend.min.css";
import "./nafl-html/css/style_1.css";
import "./nafl-html/css/style.css";
import "./nafl-html/css/styles.css";
import "./nafl-html/css/style.min.css";
import "./nafl-html/css/responsive.css";
// import './nafl-html/css/style_1.css'
import "./nafl-html/css/animate.css";
import "./nafl-html/css/animations.min.css";

import "./nafl-html/css/classic-themes.min.css";
import "./nafl-html/css/color.css";
import "./nafl-html/css/custom-animate.css";
import "./nafl-html/css/custom.css";
import "./nafl-html/css/elementor-icons.min.css";
import "./nafl-html/css/elpath.css";
import "./nafl-html/css/fontawesome-all.css";
// import './nafl-html/css/frontend.min.css'
import "./nafl-html/css/global.css";
import "./nafl-html/css/hover.css";
import "./nafl-html/css/icomoon.css";
import "./nafl-html/css/jquery-ui-1.9.2.custom.min.css";
import "./nafl-html/css/jquery.fancybox.min.css";
import "./nafl-html/css/jquery.touchspin.css";
import "./nafl-html/css/nice-select.css";
import "./nafl-html/css/owl.css";
import "./nafl-html/css/post-9.css";
import "./nafl-html/css/rtl.css";
// import './nafl-html/css/scrollbar.css'
import "./nafl-html/css/swiper.min.css";
import "./nafl-html/css/wc-blocks-style.css";
import "./nafl-html/css/wc-blocks-vendors-style.css";
import "./nafl-html/css/woocommerce_1.css";
import "./nafl-html/css/woocommerce-layout.css";
import "./nafl-html/css/woocommerce-smallscreen.css";
import "./nafl-html/css/woocommerce.css";
import "./App.css";

// import ClipLoader from "react-spinners/ClipLoader";

import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/Index";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import AboutUsPage from "./pages/aboutUs/Index";
import ContactUsPage from "./pages/contactUs/Index";
import { useEffect, useState } from "react";
import WaterForHealthPage from "./pages/waterForHealth/Index";

import GalleryPage from "./pages/gallery/Index";
import VideoPage from "./pages/video/Index";
import OurMissionPage from "./pages/ourMission";

import translation from "../src/translationEngtoArb/Data.json";
import OurProductsPage from "./pages/ourProducts";

function App() {
  const [showSideBar, setShowSideBar] = useState(true);

  const [language, setLanguage] = useState("english");
  const [content, setContent] = useState({});

  const handleSidebar = () => {
    setShowSideBar(!showSideBar);
  };
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setModalShow(true);
    });
  }, [5000]);

  // const onHide = () => {
  //   setModalShow(false);
  // };

  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 2000);
  }

  useEffect(() => {
    if (language == "english") {
      setContent(translation.english);
      window.localStorage.setItem("language", "english");
    } else if (language == "arabic") {
      setContent(translation.arabic);
      window.localStorage.setItem("language", "arabic");
    }
  }, [language]);

  return (
    <>
      <div className="App">
        <Header
          handleSidebar={handleSidebar}
          setLanguage={setLanguage}
          content={content}
        />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route
            path="/home"
            element={<HomePage setLanguage={setLanguage} content={content} />}
          />
          <Route path="/about" element={<AboutUsPage content={content} />} />
          <Route
            path="/contact"
            element={<ContactUsPage content={content} />}
          />
          <Route
            path="/water-for-health"
            element={<WaterForHealthPage content={content} />}
          />
          <Route path="/gallery" element={<GalleryPage content={content} />} />
          <Route path="/video" element={<VideoPage content={content} />} />
          <Route
            path="/mission"
            element={<OurMissionPage content={content} />}
          />
          <Route path="/mission" element={<OurProductsPage />} />
        </Routes>
        <Footer content={content} />
      </div>
    </>
  );
}

export default App;
