import React from "react";
import ContactUs from "../../components/contactUs/ContactUs";

function ContactUsPage({ content }) {
  
  return (
    <>
      <ContactUs content={content} />
    </>
  );
}
export default ContactUsPage;
