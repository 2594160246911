import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import helth1 from "../../../src/nafl-html/images/water-heath/helth-img-1.jpg";
import helth2 from "../../../src/nafl-html/images/water-heath/helth-img-2.jpg";
import helth3 from "../../../src/nafl-html/images/water-heath/helth-img-3.jpg";
import helth4 from "../../../src/nafl-html/images/water-heath/helth-img-4.jpg";
import helth5 from "../../../src/nafl-html/images/water-heath/helth-img-5.jpg";
import helth6 from "../../../src/nafl-html/images/water-heath/helth-img-6.jpg";
import helth7 from "../../../src/nafl-html/images/water-heath/helth-img-7.jpg";
import helth8 from "../../../src/nafl-html/images/water-heath/helth-img-8.jpg";
import helth9 from "../../../src/nafl-html/images/water-heath/helth-img-9.jpg";
import WaterBenefits from "./waterBenefits/WaterBenefits";
import Testimonials from "../home/testimonials/Testimonials";

// const health = [
//   {
//     id: "1",
//     img: helth1,
//     heading:
//       "Find out the impact of lead in your drinking water, and how to eliminate it.",
//     paragraph:
//       "Find out the impact of lead in your drinking water, and how to eliminate it.",
//   },
//   {
//     id: "2",
//     img: helth2,
//     heading: "5 Reasons Why Your Skin Needs to Stay Hydrated",
//     paragraph:
//       "Drinking mineral water that is going to prove very beneficial for your skin in the long run.",
//   },
//   {
//     id: "3",
//     img: helth3,
//     heading: "Understanding the Relation Between Hydration and Mental Health",
//     paragraph:
//       "Your mineral water intake has a huge impact on your mental health.",
//   },
//   {
//     id: "4",
//     img: helth4,
//     heading: "Take a Rain Check: Do’s and Don’ts for a Healthy Monsoon",
//     paragraph:
//       "Monsoon season calls for extra care and attention being paid to our health.",
//   },
//   {
//     id: "5",
//     img: helth5,
//     heading: "Benefits of Drinking More Water in Monsoon Season",
//     paragraph:
//       "It is imperative you keep drinking water and stay hydrated throughout the monsoon season.",
//   },
//   {
//     id: "6",
//     img: helth6,
//     heading: "Why  NAFL is the best I can give my daughter",
//     paragraph:
//       "We parents have an enormous responsibility when it comes to the well-being of our children.",
//   },
//   {
//     id: "7",
//     img: helth7,
//     heading:
//       "Har paani ki bottle  NAFL nahi! (Not every bottle of water is  NAFL)",
//     paragraph:
//       "THOUSANDS HAVE LIVED WITHOUT LOVE, NOT ONE WITHOUT WATER.” – W.H. AUDEN.",
//   },
//   {
//     id: "8",
//     img: helth8,
//     heading:
//       "Is drinking bottled water safe and beneficial for your child’s health? Find out!",
//     paragraph:
//       "Water is life, and clean water means health.’ The question that arises here is, are we drinking clean water?",
//   },
//   {
//     id: "9",
//     img: helth9,
//     heading: "Visit to the  NAFL plant in Mumbai and why  NAFL is my favourite",
//     paragraph:
//       "I started my career as a Radio Jockey back in 2006, and have always been a working woman my life,",
//   },
// ];

function WaterForHealth({ content }) {
  // const [item, setItem] = useState(health);
  // const [visivel, setVisivel] = useState(3);
  // const showMoreItem = () => {
  //   setVisivel((previousValue) => previousValue + 3);
  // };
  console.log(content);
  return (
    <>
      <section
        className="page-title page-title-2"
        style={{
          backgroundImage:
            'url("http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/10/image-26.png")',
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="title">
                <h1>{content.waterforhelth}</h1>
              </div>
              <ul className="bread-crumb clearfix">
                <li className="breadcrumb-item">
                  <Link to="/home">{content.home} &nbsp;</Link>
                </li>
                <li className="breadcrumb-item">{content.waterforhelth}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="sticky_wrap">
        <section className="water-for-health helth_water">
          <div className="wrap_layer"></div>

          <WaterBenefits content={content} />
        </section>
      </div>
      <Testimonials content={content} />
    </>
  );
}
export default WaterForHealth;
