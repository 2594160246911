import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Testimonials from "../home/testimonials/Testimonials";

function VideoComp({ content }) {
  return (
    <>
      <section
        className="page-title page-title-2"
        style={{
          backgroundImage:
            'url("http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/10/image-26.png")',
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="title">
                <h1>{content.video}</h1>
              </div>
              <ul className="bread-crumb clearfix">
                <li className="breadcrumb-item">
                  <Link to="/home">{content.home} &nbsp;</Link>
                </li>
                <li className="breadcrumb-item">{content.video}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="pt_120-pt">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-1">
              <div className="video-section">
                <ReactPlayer
                  url="https://youtu.be/kCqXqb14v1A"
                  className="nafl_video"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-1">
              <div className="video-section">
                <ReactPlayer
                  url="https://youtu.be/Ip37M6OeTew"
                  className="nafl_video"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-1">
              <div className="video-section">
                <ReactPlayer
                  // url="https://youtube.com/shorts/2rffL1jMiZM?feature=share"
                  url="https://youtube.com/shorts/2rffL1jMiZM?si=g5HvulTM-uOusYWh"
                  className="nafl_video"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials content={content} />
    </>
  );
}
export default VideoComp;
