import React from "react";
import Gallery from "../../components/gallery/Gallery";

function GalleryPage({content}) {
  return (
    <>
      <Gallery content={content} />
    </>
  );
}
export default GalleryPage;
