// import React from "react";
// import MobileMenu from "../mobile-Menu/Mobile-Menu";
import StickyHeader from "./sticky-header/StickyHeader";
import TopHeader from "./topHeader/TopHeader";
import UperHeader from "./uperHeader/UpperHeader";

function Header({ handleSidebar, setLanguage, content }) {
  return (
    <div className="main-header header-style-one">
      <TopHeader setLanguage={setLanguage} content={content} />
      <UperHeader
        handleSidebar={handleSidebar}
        setLanguage={setLanguage}
        content={content}
      />
      <StickyHeader />
      {/* <MobileMenu /> */}
    </div>
  );
}
export default Header;
