import React from "react";
import { Link } from "react-router-dom";

function ContactUs({ content }) {
  return (
    <>
      <section
        className="page-title page-title-2"
        style={{
          backgroundImage:
            'url("http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/10/image-26.png")',
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="title">
                <h1>{content.contactUsMain?.contactusbanner}</h1>
              </div>
              <ul className="bread-crumb clearfix">
                <li className="breadcrumb-item">
                  <Link to="/home">{content.home} &nbsp;</Link>
                </li>
                <li className="breadcrumb-item">
                  {content.contactUsMain?.contactusbanner}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fuild">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3628.7522360102853!2d46.74856795055732!3d24.563222663077898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0935a48f2163%3A0x12b6a518820d3b7e!2z2YXYpNiz2LPYqSDYp9mE2YXZiNmF2YrYp9ihINmE2YTYqtis2KfYsdip!5e0!3m2!1sen!2sin!4v1676299129456!5m2!1sen!2sin"
                width="100%"
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="auto-container">
          <div className="wrapper-box b_shadow_1 b_radius_5 px_50 pt_60 pb_35">
            <div className="row">
              <div className="col-lg-7">
                <div className="sec-title mb_30">
                  <h2 className="fs_45 fw_sbold">
                    {content.contactUsMain?.requestContact}
                  </h2>
                  <div className="text">
                    {content.contactUsMain?.requestContactH}
                  </div>
                </div>
                <div className="contact-form">
                  <div
                    role="form"
                    className="wpcf7"
                    id="wpcf7-f498-p53-o1"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p role="status" aria-live="polite" aria-atomic="true" />
                      <ul />
                    </div>
                    <form
                      action="/newwp/paanee/contact/#wpcf7-f498-p53-o1"
                      method="post"
                      className="wpcf7-form init"
                      noValidate="novalidate"
                      data-status="init"
                    >
                      <div className="row">
                        <div className="form-group col-md-6">
                          <span className="wpcf7-form-control-wrap text-343">
                            <input
                              type="text"
                              name="text-343"
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                              placeholder={content.contactUsMain?.yourname}
                            />
                          </span>
                        </div>
                        <div className="form-group col-md-6">
                          <span className="wpcf7-form-control-wrap email-947">
                            <input
                              type="email"
                              name="email-947"
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                              placeholder={content.contactUsMain?.email}
                            />
                          </span>
                        </div>
                        <div className="form-group col-md-6">
                          <span className="wpcf7-form-control-wrap text-344">
                            <input
                              type="text"
                              name="text-344"
                              size={40}
                              className="wpcf7-form-control wpcf7-text"
                              placeholder={content.contactUsMain?.phone}
                            />
                          </span>
                        </div>
                        <div className="form-group col-md-6">
                          <span className="wpcf7-form-control-wrap text-345">
                            <input
                              type="text"
                              size={40}
                              className="wpcf7-form-control wpcf7-text"
                              placeholder={content.contactUsMain?.subject}
                            />
                          </span>
                        </div>
                        <div className="form-group col-md-12">
                          <span className="wpcf7-form-control-wrap textarea-987">
                            <textarea
                              name="textarea-987"
                              cols={40}
                              rows={10}
                              className="wpcf7-form-control wpcf7-textarea"
                              placeholder={content.contactUsMain?.massage}
                            />
                          </span>
                        </div>
                        <div className="form-group col-md-12">
                          <button
                            className="theme_btn_1 paanee_btn"
                            type="button"
                            data-loading-text="Please wait..."
                          >
                            <span>
                              {content.contactUsMain?.massageSendbtnContact}
                              <i className="flaticon-next" />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div
                        className="wpcf7-response-output"
                        aria-hidden="true"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="contact-info theme_bg px_30 pt_40 pb_1">
                  <h3 className="title fs_30 fw_bold color_white mb_30">
                    {content.contactUsMain?.contactusbanner}
                  </h3>
                  <div className="contact-info">
                    <ul className="list color_white">
                      <li className="mb_45 flexbox_1">
                        <div className="icon w_55 h_55 lh_60 text-center mr_20 bg_white theme_color fs_30 b_radius_5">
                          <i className="icon-address" />
                        </div>
                        <div>
                          <h4 className="fs_16 fw_bold color_white mb_10">
                            {content.contactUsMain?.ourAdd}
                          </h4>
                          <div className="text color_white">
                            {content.contactUsMain?.addressMain}
                          </div>
                        </div>
                      </li>

                      <li className="mb_45 flexbox_1">
                        <div className="icon w_55 h_55 lh_60 text-center mr_20 bg_white theme_color fs_30 b_radius_5">
                          <i className="icon-phone" />
                        </div>
                        <div>
                          <h4 className="fs_16 fw_bold color_white mb_10">
                            {content.contactUsMain?.ourPhone}
                          </h4>
                          <div className="text color_white">
                            {content.contactUsMain?.officialTelephone}
                            <a
                              className="color_white"
                              href="tel:+966 542058487"
                            >
                              +966 542058487
                            </a>
                            <br />
                            {content.contactUsMain?.mobile}:
                            <a
                              className="color_white"
                              href="tel:+966 542058487"
                            >
                              +966 542058487
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="mb_45 flexbox_1">
                        <div className="icon w_55 h_55 lh_60 text-center mr_20 bg_white theme_color fs_30 b_radius_5">
                          <i className="icon-email" />
                        </div>
                        <div>
                          <h4 className="fs_16 fw_bold color_white mb_10">
                            {content.contactUsMain?.ourEmail}
                          </h4>
                          <div className="text color_white">
                            {content.contactUsMain?.mainEmail}:
                            <a
                              className="color_white"
                              href="mailto:  info@nafl.pro"
                            >
                              {" "}
                              info@nafl.pro
                            </a>
                            <br />
                            {content.contactUsMain?.Inquiries} :
                            <a
                              className="color_white"
                              href="mailto: info@nafl.pro"
                            >
                              info@nafl.pro
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactUs;
