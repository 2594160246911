import React from "react";

// import ExprienceWorker from "./exprienceWorker/ExprienceWorker";
import OurProducts from "./ourProduct/OurProducts";
import Testimonials from "./testimonials/Testimonials";
import WhoAreSection from "./whoAreSection/WhoAreSection";
import MainBannerPage from "../../pages/mainBanner";

function Home({ content }) {
  return (
    <>
      <MainBannerPage content={content} />

      {/* <ExprienceWorker content={content} /> */}
      <OurProducts content={content} />
      <WhoAreSection content={content} />
      <Testimonials content={content} />
    </>
  );
}
export default Home;
