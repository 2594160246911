import React from "react";

import Slider from "react-slick";

import testimonial1 from "../../../../src/nafl-html/images/testimonial.png";
import testimonial2 from "../../../../src/nafl-html/images/testimonial-2.png";
import testimonial3 from "../../../../src/nafl-html/images/testimonial-3.png";

// import testimonialData from "./TestimonialData";

function Testimonials({ content }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonials-section testimonials-section_2 pt_120-pt py-5">
        <div className="auto-container">
          <div className="sec-title mb_50 text-center pb-3">
            <h5 className="mb_20 fs_16 fw_medium text-uppercase theme_color ff_rubik">
              {content.clientTestimonial?.heading}
            </h5>
            <h2 className="fs_45 fw_sbold ">
              {content.clientTestimonial?.title}
            </h2>
          </div>

          <div className="slider-container">
            <Slider {...settings}>
              <div className="testimonial_block_box">
                <div className="testimonial_block">
                  <img
                    src={testimonial1}
                    alt=""
                    className="testimonial_img_fluid"
                  />
                </div>
                <p>{content.clientTestimonial?.reviewA}</p>
                <div className="client">
                  <h4>{content.clientTestimonial?.userNameA}</h4>
                </div>
              </div>

              <div className="testimonial_block_box">
                <div className="testimonial_block">
                  <img
                    src={testimonial2}
                    alt=""
                    className="testimonial_img_fluid"
                  />
                </div>
                <p>{content.clientTestimonial?.reviewF}</p>
                <div className="client">
                  <h4 class="fs_16 fw_bold mb_5">
                    {content.clientTestimonial?.userNameF}{" "}
                  </h4>
                </div>
              </div>

              <div className="testimonial_block_box">
                <div className="testimonial_block">
                  <img
                    src={testimonial3}
                    alt=""
                    className="testimonial_img_fluid"
                  />
                </div>
                <p>{content.clientTestimonial?.reviewAG}</p>
                <div className="client">
                  <h4>{content.clientTestimonial?.userNameAG}</h4>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
export default Testimonials;
