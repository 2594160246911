import React from "react";
import WaterForHealth from "../../components/waterForHealth/WaterForHealth";

function WaterForHealthPage({content}) {
  return (
    <>
      <WaterForHealth content={content} />
    </>
  );
}
export default WaterForHealthPage;
