import React from "react";
import AboutUsC from "../../components/aboutUs/AboutUs";

function AboutUsPage({content}) {
  return (
    <>
      <AboutUsC content={content} />
    </>
  );
}
export default AboutUsPage;
