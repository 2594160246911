import React from "react";
import Home from "../../components/home/Home";

function HomePage({ content }) {
  return (
    <>
      <Home content={content} />
    </>
  );
}
export default HomePage;
