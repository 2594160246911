import React from "react";
function TopHeader({ content }) {
  return (
    <>
      <div className="top_header_main_box">
        <div className="header-top">
          <div className="auto-container">
            <div className="row top_header_row">
              <div className="col-lg-6 top_header_location_add">
                <h6>
                  <i className="icon-pin" /> {content.address}
                </h6>
              </div>

              <div className="col-lg-6">
                <div className="info_nfl_icon">
                  <div className="top_header_info_nafl">
                    <h6 className="text-center">
                      <i className="icon-envelope" />{" "}
                      <a href="mailto: info@nafl.pro" className="info_nafl">
                        {" "}
                        info@nafl.pro
                      </a>
                    </h6>
                  </div>
                  <div className="top_header_list-icons">
                    <ul className="social-links social-links-top-header">
                      <li>
                        <a href="https://www.facebook.com/">
                          <i className="fab fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.skype.com/">
                          <i className="fab fa-skype" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TopHeader;
