import OurProduct from "../../components/ourProduct/OurProduct";

function OurProductsPage() {
  return (
    <>
      <div>
        <OurProduct />
      </div>
    </>
  );
}
export default OurProductsPage;
