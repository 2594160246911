import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaLinkedin,
} from "react-icons/fa";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import logo from "../../../../../src/nafl-html/images/new-img/logo/logo.jpeg";
import togleButton from "../../../../../src/nafl-html/images/icon-bar.png";

function UperHeader({ setLanguage, content }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeLanguage = (e) => {
    setLanguage(e.target.value);
    window.localStorage.setItem("language", e.target.value);
  };
  return (
    <>
      <div className="header-upper">
        <div className="auto-container">
          <div className="inner-container">
            {/*Logo*/}
            <div className="logo-box">
              <div className="logo logo-logo">
                <Link to="/home" title="Paanee">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                onChange={changeLanguage}
              >
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </Form.Select>
            </div>
            <div className="right-column">
              <div className="nav-outer">
                <div className="mobile-nav-toggler">
                  <img
                    src={togleButton}
                    alt="Awesome Images"
                    onClick={handleShow}
                  />
                </div>

                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation">
                      <li
                        id="menu-item-56"
                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-56 dropdown active current"
                      >
                        <Link
                          title="Home"
                          to="/home"
                          data-toggle="dropdown1"
                          className="hvr-underline-from-left1"
                          aria-expanded="false"
                          data-scroll
                          data-options="easing: easeOutQuart"
                        >
                          {content.home}
                        </Link>

                        <div className="dropdown-btn">
                          <span className="fa fa-angle-right" />
                        </div>
                      </li>
                      <li
                        id="menu-item-58"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-58"
                      >
                        <Link
                          title="About Us"
                          to="/about"
                          className="hvr-underline-from-left1"
                          data-scroll
                          data-options="easing: easeOutQuart"
                        >
                          {content.aboutus}
                        </Link>
                      </li>
                      <li
                        id="menu-item-58"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-58"
                      >
                        <Link
                          title="Water for Health"
                          to="/water-for-health"
                          className="hvr-underline-from-left1"
                          data-scroll
                          data-options="easing: easeOutQuart"
                        >
                          {content.waterforhelth}
                        </Link>
                      </li>
                      <li
                        id="menu-item-58"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-58"
                      >
                        <Link
                          title="Gallery"
                          to="/gallery"
                          className="hvr-underline-from-left1"
                          data-scroll
                          data-options="easing: easeOutQuart"
                        >
                          {content.gallery}
                        </Link>
                      </li>

                      <li
                        id="menu-item-63"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-63"
                      >
                        <Link
                          title="Video"
                          to="/video"
                          className="hvr-underline-from-left1"
                          data-scroll
                          data-options="easing: easeOutQuart"
                        >
                          {content.video}
                        </Link>
                      </li>

                      <li
                        id="menu-item-63"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-63"
                      >
                        <Link
                          title="Contact"
                          to="/contact"
                          className="hvr-underline-from-left1"
                          data-scroll
                          data-options="easing: easeOutQuart"
                        >
                          {content.contact}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="navbar-right-info">
                <div className="link-btn">
                  <a href="#" className="theme_btn_1 paanee_btn">
                    {content.getquote}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="ofcanvas_small_scren"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={logo} alt="" className="ofcanvas_logo" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="canvas_item">
            <li className="canvas_item_list">
              <Link to="/home" className="canvas_list_link">
                {content.home}
              </Link>
            </li>

            <li className="canvas_item_list">
              <Link to="/about" className="canvas_list_link">
                {content.aboutus}
              </Link>
            </li>

            <li className="canvas_item_list">
              <Link to="/water-for-health" className="canvas_list_link">
                {content.waterforhelth}
              </Link>
            </li>

            <li className="canvas_item_list">
              <Link to="/gallery" className="canvas_list_link">
                {content.gallery}
              </Link>
            </li>

            <li className="canvas_item_list">
              <Link to="/video" className="canvas_list_link">
                {content.video}
              </Link>
            </li>

            <li className="canvas_item_list">
              <Link to="/contact" className="canvas_list_link">
                {content.contact}
              </Link>
            </li>

            <li className="canvas_item_list">
              <Link to="/mission" className="canvas_list_link">
                {content.ourMissionn}
              </Link>
            </li>
          </ul>
        </Offcanvas.Body>
        <footer className="offcanvas_footer">
          <ul className="ofcanvas_footer_social_link_item">
            <li className="ofcanvas_footer_social_link_list">
              <Link to="" className="ofcanvas_footer_social_link">
                <FaFacebookSquare className="ofcanvas_footer_social_link_icon" />
              </Link>
            </li>

            <li className="ofcanvas_footer_social_link_list">
              <Link to="" className="ofcanvas_footer_social_link">
                <FaTwitterSquare className="ofcanvas_footer_social_link_icon" />
              </Link>
            </li>
            <li className="ofcanvas_footer_social_link_list">
              <Link to="" className="ofcanvas_footer_social_link">
                <FaInstagramSquare className="ofcanvas_footer_social_link_icon" />
              </Link>
            </li>
            <li className="ofcanvas_footer_social_link_list">
              <Link to="" className="ofcanvas_footer_social_link">
                <FaLinkedin className="ofcanvas_footer_social_link_icon" />
              </Link>
            </li>
          </ul>
        </footer>
      </Offcanvas>
    </>
  );
}
export default UperHeader;
