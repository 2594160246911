import React from "react";
import img1 from "../../../../src/nafl-html/images/image-5.png";
function WhoAreSection({ content }) {
  return (
    <>
      <section className="who-we-are pt_120-pt p_relative theme_bg">
        <div className="auto-container">
          <div className="sec-title mb_50 text-center">
            <h5 className="mb_20 fs_16 fw_medium text-uppercase theme_color ff_rubik">
              {content.whoSection?.heading}
            </h5>{" "}
            <h2 className="fs_45 fw_sbold">{content.whoSection?.title}</h2>{" "}
          </div>
          <div className="video-box p_relative mb_40">
            <img decoding="async" src={img1} alt="Awesome Image" className="videobox_img"/>{" "}
            <div className="video-btn">
              <a
                href="https://www.youtube.com/watch?v=hv8rs4zAl5Q"
                className="h_140 w_140 lh_140 text-center fs_40 theme_color d_iblock p_relative bg_white b_radius_50 tran_3 overlay-link play-now ripple"
                data-fancybox="gallery"
                data-caption
              >
                <i className="fas fa-play p_relative z_1" />
              </a>
            </div>{" "}
          </div>

          <div className="funfacts pt_90">
            <div className="title text-center p_relative mb_65">
              <h2 className="fs_37 fw_sbold d_iblock p_relative z_1 client_testimonial_title">
                {content.whoSection?.achievementsHeader}
              </h2>
            </div>
            <div className="row w-100">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 funfact-block">
                <div className="inner-box mb_30">
                  <div className="flexbox_3 align-items-center">
                    <div className="icon w_75 h_75 lh_80 text-center mr_15 color_white fs_40 b_radius_50 mr_20">
                      <i className="fa icon-user" />
                    </div>
                    <div>
                      <div className="count-outer count-box fs_34 fw_sbold color_white ff_jost counted">
                        <span
                          className="count-text"
                          data-speed={3000}
                          data-stop={35000}
                        >
                          35000
                        </span>
                      </div>
                      <div className="text color_white fs_18">
                        Happy Customers
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 funfact-block">
                <div className="inner-box mb_30">
                  <div className="flexbox_3 align-items-center">
                    <div className="icon w_75 h_75 lh_80 text-center mr_15 color_white fs_40 b_radius_50 mr_20">
                      <i className="fa icon-document" />
                    </div>
                    <div>
                      <div className="count-outer count-box fs_34 fw_sbold color_white ff_jost counted">
                        <span
                          className="count-text"
                          data-speed={3000}
                          data-stop={541}
                        >
                          541
                        </span>
                      </div>
                      <div className="text color_white fs_18">
                        Projects Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 funfact-block">
                <div className="inner-box mb_30">
                  <div className="flexbox_3 align-items-center">
                    <div className="icon w_75 h_75 lh_80 text-center mr_15 color_white fs_40 b_radius_50 mr_20">
                      <i className="fa icon-daimond" />
                    </div>
                    <div>
                      <div className="count-outer count-box fs_34 fw_sbold color_white ff_jost counted">
                        <span
                          className="count-text"
                          data-speed={3000}
                          data-stop={40}
                        >
                          40
                        </span>
                      </div>
                      <div className="text color_white fs_18">Awards Win</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 funfact-block">
                <div className="inner-box mb_30">
                  <div className="flexbox_3 align-items-center">
                    <div className="icon w_75 h_75 lh_80 text-center mr_15 color_white fs_40 b_radius_50 mr_20">
                      <i className="fa icon-diagram" />
                    </div>
                    <div>
                      <div className="count-outer count-box fs_34 fw_sbold color_white ff_jost counted">
                        <span
                          className="count-text"
                          data-speed={3000}
                          data-stop={678}
                        >
                          678
                        </span>
                      </div>
                      <div className="text color_white fs_18">Client Works</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhoAreSection;
