// import bannerimg1 from "../../../src/nafl-html/images/banner/nafl.jpg";
import bannerimg1 from "../../../src/nafl-html/images/banner/nafl-min.jpg";
import bannerimg2 from "../../../src/nafl-html/images/banner/nafl1-min.jpg";

function MainBanner({ content }) {
  const language = window.localStorage.getItem("language");

  return (
    <>
      <section className="main_banner_sec">
        <div className="auto-container">
          <div className="banner_img_sec">
            <img
              src={language == "english" ? bannerimg1 : bannerimg2}
              alt=""
              className="main_banner_img"
            />
          </div>
        </div>
      </section>
    </>
  );
}
export default MainBanner;
