function OurMission({ content }) {
  const language = window.localStorage.getItem("language");
  return (
    <>
      <section className="water-for-health pt_120-pt">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec-title mb_50 text-center">
                <h2 className="fs_45 fw_sbold">
                  {content.ourMission?.desHeading}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_mission pb-5">
        <div className="auto-container">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="sec-title our_mission_details_sec">
                  <ul className={language == "arabic" && "text-end"}>
                    {content.ourMission?.ourMissionDescrip.map((item) => {
                      return (
                        <li className="our_mission_details_list">
                          {item.details}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default OurMission;
