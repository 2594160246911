import MainBanner from "../../components/mainBanner/MainBanner";

function MainBannerPage({ content }) {
 
  return (
    <>
      <MainBanner content={content} />
    </>
  );
}
export default MainBannerPage;
