import React from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { useState } from "react";
import { Link } from "react-router-dom";

import g1 from "../../nafl-html/images/gallery/g-1.png";
import g2 from "../../nafl-html/images/gallery/g-2.png";

import g4 from "../../nafl-html/images/gallery/g-4.png";
import g5 from "../../nafl-html/images/gallery/g-5.png";
import g6 from "../../nafl-html/images/gallery/g-6.png";
import Testimonials from "../home/testimonials/Testimonials";

const gallery = [
  { id: "1", url: g1, alt: "img" },
  { id: "2", url: g2, alt: "img" },
  { id: "4", url: g4, alt: "img" },
  { id: "5", url: g5, alt: "img" },
  { id: "6", url: g6, alt: "img" },
];

function Gallery({ content }) {
  return (
    <>
      <section
        className="page-title page-title-2"
        style={{
          backgroundImage:
            'url("http://fastwpdemo.com/newwp/paanee/wp-content/uploads/2021/10/image-26.png")',
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="title">
                <h1>{content.gallery}</h1>
              </div>
              <ul className="bread-crumb clearfix">
                <li className="breadcrumb-item">
                  <Link to="/home">{content.home} &nbsp;</Link>
                </li>
                <li className="breadcrumb-item">{content.gallery}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="gallery-section pt_120-pt">
        <div className="auto-container">
          <div className="row">
            <SlideshowLightbox className="box-gallery">
              {gallery.slice(0, 6).map((item) => {
                return <img className="w-full rounded" src={item.url} />;
              })}
            </SlideshowLightbox>
          </div>
        </div>
      </section>

      <Testimonials content={content} />
    </>
  );
}

export default Gallery;
